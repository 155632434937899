.login-logo {
    max-width: 50vw;
    width: 300px;
    margin: 2em auto;
}

.login-page {
    width: 80vw;
    max-width: 500px;
    margin: 10vh auto 0;
}
