.site-card-row {
    margin-left: calc(-1em + -5px);
    margin-right: calc(-1em + -5px);
}

.site-card-container ion-card {
    height: calc(100% - 2em);
    margin: 1em;
    width: calc(100% - 2em);
}

.site-card-container ion-card-title {
    display: flex;
    justify-content: space-between;
    font-size: 1em;
}

.site-card-container ion-card-content {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    font-size: 1em;
}
