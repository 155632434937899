.sensor-card-row {
    margin-left: calc(-.5em + -5px);
    margin-right: calc(-.5em + -5px);
}

.sensor-card-container ion-card {
    height: calc(100% - 1em) !important;
    margin: .5em !important;
    width: calc(100% - 1em) !important;
}

.sensor-card-container ion-card-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
}

.sensor-card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
}

.sensor-card-title-badges {
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.sensor-card-ding-row {
    display: flex;
    justify-content: space-between;
}

.sensor-card-details {
    font-size: 1em;
}

.sensor-card-button-container {
    margin-left: 1em;
}
