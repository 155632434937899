ion-split-pane {
    --side-width: 250px;
}

ion-item.no-padding {
    --padding-start: 0;
}

ion-spinner {
    --color: var(--ion-color-primary);
}

.no-link {
    text-decoration: none !important;
}

.color-danger { color: var(--ion-color-danger); }
.color-primary { color: var(--ion-color-primary); }
.color-success { color: var(--ion-color-success); }
.color-warning { color: var(--ion-color-warning); }
.color-muted { color: #c5c5c5; }

@media (prefers-color-scheme: dark) {
    .color-text { color: #ffffff !important; }
}

.ion-text-bold, h1, h2, h3, h4, h5, h6 { font-weight: bold; }

.site-card-container, .sensor-card-container {
    width: 100%;
    height: 100%;
    margin: 0;
}

.site-card-container ion-card, .sensor-card-container ion-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.horizontal-scroll-container {
    width: 100%;
    padding-bottom: .5em;
    overflow-x: scroll;
    white-space: nowrap;
}

.sidebar-grid-side-column {
    background: var(--side-column-bg);
    z-index: 101;
}

@media screen and (max-width: 1199.99px) {
    .sidebar-grid-container {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
    }

    .sidebar-grid-main-column {
        position: absolute;
        width: 100%;
    }

    .sidebar-grid-side-column {
        position: absolute;
        right: -100%;
        width: 100%;
        min-height: 100%;
        transition: all .4s, transform .4s;
    }

    .sidebar-grid-container.sidebar-toggled .sidebar-grid-side-column {
        right: 0;
    }

    .sidebar-grid-container.sidebar-toggled .sidebar-grid-main-column {
        max-height: 100%;
        overflow: hidden;
    }

    .sidebar-grid-container:not(.sidebar-toggled) .sidebar-grid-side-column {
    }
}

@media screen and (min-width: 1200px) {
    .sidebar-grid-container {
        display: flex;
        min-height: 100%;
    }

    .sidebar-grid-main-column {
        width: 75%;
    }

    .sidebar-grid-side-column {
        width: 25%;
    }
}

@media screen and (max-width: 1199.99px) {
    .column-view-side-column {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    .column-view-container {
        display: flex;
    }

    .column-view-main-column {
        width: 60%;
    }

    .column-view-side-column {
        width: 40%;
    }
}

.flex-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 0;
}

.flex-grow {
    flex-grow: 1;
}

.contact-detail {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    border-radius: .2em;
    margin-bottom: 1em;
    padding: .5em;
}

a.contact-detail {
    text-decoration: none;
}

a.contact-detail span {
    color: var(--ion-text-color);
}

@media (prefers-color-scheme: dark) {
    .contact-detail {
        background: #444444;
    }
}

.rounded {
    border-radius: .3em;
}

.loader {
    text-align: center;
    margin-top: 5em;
}

[role="button"] {
    cursor: pointer;
}

.site-image {
    max-width: 100%;
    height: 50px;
}

.user-image {
    max-width: 100%;
    height: 75px;
}

.sticky-header {
    position: sticky;
    top: 0;
    margin: calc(-1 * var(--ion-padding)) calc(-1 * var(--ion-padding)) 0;
    padding: 32px var(--ion-padding) 10px;
    background: var(--ion-background-color);
    z-index: 100;
}

.sidebar-toggled .sidebar-grid-main-column .sticky-header {
    top: -16px;
    z-index: 0;
}

.sidebar-grid-side-column .sticky-header {
    background: var(--side-column-bg);
    z-index: 101;
}

.danger-card {
    background: var(--danger-card-bg);
}

.offline-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000066;
    display: flex;
    justify-content: center;
    align-items: center;
}

.offline-overlay .message {
    background: #ffffff;
    padding: 16px;
    border-radius: 5px;
    text-align: center;
}

.offline-overlay .message div {
    margin-bottom: 10px;
}

.fancybox__toolbar {
    top: var(--ion-safe-area-top)
}

.download-button {
    padding: .5em;
    cursor: pointer;
}
